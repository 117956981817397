import './App.css';
import Header  from './Component/Header';
import Body  from './Component/Body';
import Footer  from './Component/Footer';

function App() {
  return (
    <div className="App">
      <Header  />

        <br/>
        <br/>
        
        <Body  />
          
        <br/>
        <br/>


        <br/>
        <br/>
        <br/>
        <br/>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>


      <header className="App-header">
        <h1>Welcome to Ram Yug</h1>
        <br/>
        <br/>
        <br/>
        <br/>

        <p>This is under construction</p>
        <p>Comming Soon</p>


        
        
      </header>

    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />
    <br />
    <br  />

    <Footer   />

    </div>
  );
}

export default App;
