import React from 'react';

class Footer extends React.Component {
    constructor() {
      super();
      this.state = {color: "red"};
    }
    render() {
      return <div >
            <p   text-align="center">Footer Works</p>
      </div>;
    }
  }



export default Footer;