import React from 'react';

class Body extends React.Component {
    constructor() {
      super();
      this.state = {color: "red"};
    }
    render() {
      return <div>
        <div>
        राम (Ram) हिन्दू धर्म में एक प्रमुख दैवत (देवता) है और विष्णु भगवान के सातवें अवतार माने जाते हैं। राम हिन्दू धर्म के एक प्रमुख इतिहासिक और धार्मिक काव्य, रामायण, के प्रमुख पात्र में हैं जिसमें उनका विवादशील पराक्रम, धर्म के प्रति प्रतिबद्धता, और आदर्श राजा के रूप में परिचित किया गया है। राम और उनकी पत्नी सीता का कथा रामायण महाकाव्य में विस्तार से वर्णित है, जिसमें राम दशरथ के पुत्र होते हैं और उनका परम भक्त हनुमान भी होता है। राम और सीता का पतिव्रत्ता और एक दूसरे के प्रति प्रेम का प्रतीक माना जाता है। हिन्दू धर्म में राम भक्ति का महत्वपूर्ण स्थान है, और रामनवमी, दीपावली जैसे त्योहार इसकी चर्चा और आराधना के अवसरों पर मनाए जाते हैं।
        </div>
        <br />
          <div>
            
दशरथ (Dasharath) हिन्दू धर्म के एक प्रमुख राजा थे, और वे भगवान राम के पिता थे। दशरथ का कथा भगवान राम के जीवन के प्रमुख परिवारिक संबंधों में एक है। वे आयोध्या के राजा थे और उनकी तीन पत्नियाँ थीं - कौसल्या, कैकेयी, और सुमित्रा।

भगवान राम दशरथ के पुत्र थे, और उनके साथ ही भगवान लक्ष्मण, भगवान भरत, और शत्रुघ्न भी थे। दशरथ का दुःखद पल उस समय आया जब उन्होंने अपनी पत्नी कैकेयी की एक दिव्य वरदान का इस्तेमाल करने के लिए राम को वनवास भेजने का निर्णय किया, जिससे राम का वनवास हुआ और रामायण का प्रमुख कथा शुरू हुआ।

दशरथ का कथा भारतीय साहित्य और धर्मिक ग्रंथों में महत्वपूर्ण है, और उन्हें एक धार्मिक और आदर्श राजा के रूप में याद किया जाता है।
          </div>
          <br></br>
          <h1>Janm</h1>
          <div>
          भगवान राम का जन्म कथा हिन्दू धर्म के प्रमुख धार्मिक ग्रंथ, रामायण, में विस्तार से वर्णित है।

भगवान राम का जन्म आयोध्या नगरी में हुआ था। उनके पिता का नाम राजा दशरथ था और माता का नाम कौसल्या था। राजा दशरथ के यज्ञ से उन्हें चार पुत्र मिले थे - राम, लक्ष्मण, भरत, और एक पुत्री शत्रुघ्न।

राम का जन्म 9 चैत्र मास की नवमी तिथि को हुआ था, जिसे हिन्दी पंचांग में "राम नवमी" कहा जाता है और इसे भारतीय कैलेंडर के अनुसार चैत्र मास की शुक्ल पक्ष की नवमी को मनाया जाता है।

राम का जन्म अद्वितीय प्रकृति श्रीमान नारायण के अवतार माना जाता है और उन्हें धरती पर धर्म की रक्षा के लिए आवतारित होने का कारण माना जाता है। उनका जीवन और उनकी कथाएं हिन्दू धर्म में महत्वपूर्ण हैं और रामायण उनके जीवन की महाकाव्य है जो भगवान वाल्मीकि द्वारा रचा गया था।
          </div>



          <br/>
          <h1> Hanuman Ji</h1>
          <div>
          हनुमान जी का जन्म कथा हिन्दू धर्म के प्रमुख ग्रंथों में विस्तार से वर्णित है, विशेषकर महाभारत के एक ग्रंथ, रामायण, और पुराणों में।

हनुमान जी का जन्म सोमवार (मंगलवार के रूप में) के दिन, चैत्र मास के पूर्णिमा तिथि को हुआ था। इस दिन को हिंदी पंचांग में "हनुमान जयंती" या "चैत्र पूर्णिमा" कहा जाता है, और इसे भारतीय कैलेंडर के अनुसार चैत्र मास की पूर्णिमा को मनाया जाता है।

हनुमान जी का जन्म काशीपुर नामक ग्राम में हुआ था और उनके माता-पिता का नाम केसरी और अंजना था। हनुमान जी का जन्म आजीवन ब्रह्मचारी (अविवाहित) रहने वाले देवता थे और वे भगवान शिव और माता अंजना के आशीर्वाद से उत्पन्न हुए थे।

हनुमान जी बचपन में अपनी माता के लिए सूरज को देखकर उसे एक मिठाई की भट्टी में सूर्या देव की आकृति में बदल लेते हैं, जिसके कारण सूर्या देव हनुमान जी को अपने पुत्र के रूप में स्वीकार करते हैं। हनुमान जी को अजीतेन्द्रिय, पवनपुत्र, अंजनीपुत्र, वायुपुत्र, बली, महाबीर, संकटमोचन, आदि नामों से भी जाना जाता है।

हनुमान जी भगवान राम के भक्त और सेवक थे, और उनकी महत्वपूर्ण भूमिकाएं रामायण में विस्तार से बताई गई हैं। उन्हें हिन्दू धर्म में भक्ति के अद्वितीय संदूरपुष्प, हनुमान चालीसा, और अन्य भक्ति ग्रंथों में पूजा जाता है।
          </div>
      </div>;
    }
  }



export default Body;













